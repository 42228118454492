.zoom-buttons {
    position: absolute;
    top: 20px; /* Position from the top of the container */
    right: 20px; /* Position from the right of the container */
    z-index: 999; /* Ensure the buttons are on top */
    display: flex;
    justify-content: flex-end; /* Align buttons to the right */
  }
  
  .zoom-button {
    background-color: rgba(0, 123, 255, 0.35); /* Semi-transparent white background */
    color: black; /* Button text color */
    border: 200px;
    border-color: black;
    border-radius: 50%; /* Rounded shape */
    width: 30px;
    height: 30px;
    font-size: 18px;
    cursor: pointer;
    margin: 5px; /* Add some space between buttons */
    position: relative;
  }
  
  .zoom-button:hover {
    background-color: #0056b3;
    color: white; /* Button text color */
  }
  
  .zoom-button span {
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px; /* Adjust vertical alignment */
  }

  
.controls-menu {
    position: fixed;
    top: 15%;
    left: -25%; /* Off-screen initially, adjust according to menu width */
    width: 20%;
    height: auto;
    transition: right 0.3s ease; /* Smooth sliding transition */
    z-index: 990; /* Ensure it overlays other elements */
  }
  
  .controls-menu.show-menu {
    left: 0; /* Show the menu by sliding it into view */
    display: block;
  }
  
  .show-menu-button {
    margin: 5px;
  }
  
  .close-menu-button {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000; /* Ensure it's above the sliding menu */
  }

  .controls-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    opacity: 80%;
    width: auto;
    background-color: #fff;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2); /* Optional shadow for better visual appeal */
    z-index: 1000; /* Ensure it’s on top of other elements */
    position: relative; /* Ensure it's not affected by absolute or fixed positioning */
  }

  .special-controls-wrapper {
    display: flex;
    flex-direction: column;
    /* border: 1px solid #ccc; /* Add border */
    /*padding: 1px;  Add padding for a more visually appealing look */
    border-radius: 5px;
  }
  /* Mobile-specific styles */
@media (max-width: 932px) {
  .controls-wrapper {
    position: fixed;
    bottom: 0;
    right: 1%;
  }
}

  .controls-wrapper.row {
    flex-direction: row;
  }

  .controls-wrapper.column {
    position: fixed;
    flex-direction: column;
    bottom: 0;
    right: 0;
    padding: 3px;
    width: 10%;
  }

  .special-controls-wrapper.row {
    flex-direction: row;
    padding: 1px;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(240,240,240,0.79); /* Light grey background color */
    padding: 1px 4px;
  }
  
  .button-container-row {
    justify-content: center;
    flex-direction: row;
  }
  
  @media (max-width: 932px) {
    .button-container {  
      width: 100%;
    }
  }

.control-button {
    padding: 10px 12px;
    margin: 10px;
    font-size: 12px;
    background-color: rgb(0, 123, 255);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.1s, box-shadow 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    /* Media query for smaller screens (e.g., iPhone) */
    @media (max-width: 932px) {
      font-size: 10px;
      padding: 15px 12px;
      margin: 2px;
      @media (max-width: 375px) {
        padding: 10px;
      }
    }
  }

  .control-button:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Increased shadow on hover */
  }
  
  .control-button:active {
    transform: translateY(2px);
  }
    
.time-period-label {
  margin: 5px;
  font-size: 12px;
  padding: 3px;
}
