.menu-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    background-color: rgba(0, 123, 255);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    transition: width 0.3s ease, height 0.3s ease, background-color 0.3s ease, border-radius 0.3s ease;
    z-index: 1000;
  }
  
  .hamburger-lines {
    width: 20px;
    height: 3px;
    background-color: white;
    position: relative;
    transition: opacity 0.3s ease; /* Smooth transition for hiding */
  }
  
  .hamburger-lines::before,
  .hamburger-lines::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 3px;
    background-color: white;
    left: 0;
    transition: transform 0.3s ease;
  }
  
  .hamburger-lines::before {
    top: -7px;
  }
  
  .hamburger-lines::after {
    top: 7px;
  }
  
  /* When the menu button is hovered, hide the hamburger lines */
  .menu-button:hover .hamburger-lines {
    opacity: 0;
  }
  
  .menu-button:hover {
    background-color: transparent; /* Make the button's background transparent */
    width: auto;
    height: auto;
    border-radius: 0; /* Remove the border radius */
  }
  
  .button-panel {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px;
    width: max-content;
  }

  .button-panel-date {  
    font-weight: bold;
    background-color: rgba(240,240,240,0.8); /* Light grey background color */
    padding: 5px; /* Optional: Add padding for better visual appearance */
    width: 100%; /* Make the background span the entire width */
    box-sizing: border-box; /* Ensure padding is included in the width calculation */
  }
  
  /* Display the panel and hide the hamburger lines when hovered */
  .menu-button:hover .button-panel {
    display: flex;
    opacity: 1;
  }
  
  .button-panel button {
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  

  