.alert {  
    position: absolute;
    top: 20px;
    right: 10px;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: red;
    color: white;
    padding: 10px;
    border-radius: 5px;
    opacity: 0;
    font-size: 12px;
    transition: opacity 0.5s ease-in-out;
  }
  
  .alert.show {
    opacity: 1;
    display: flex;
  }
  