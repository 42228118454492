.header-container {
    background-color: #fdcf1a;
    display: flex;
    /* justify-content: space-between; /* Align title and feedback link at opposite ends */
    margin: 5px;
    flex-shrink: 0; /* Header should not shrink */
    border-radius: 30px; /* Rounded corners */
    @media (max-width: 932px) {
      border-radius: 10px; 
    }
  }
  
.feedback-link {
  font-size: 16px;
  font-weight: bold;
  text-decoration: solid;
  color: blue;
  padding: 5px;
  margin: 2px;
  text-align: center;
  cursor: pointer;    
  /* Media query for smaller screens (e.g., iPhone) */
  @media (max-width: 932px) {
    font-size: 12px;
  }
}

.links-container {
  display: flex;
  flex-direction: column; /* Arrange links vertically */
  padding: 7px;
}

.links-container-row {
  flex-direction: row;
  display: flex;
}

.links-container a {
  margin-bottom: 2px; /* Add some spacing between links */
}

.copy-container {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center; /* Center vertically */
  padding: 10px;
  padding-right: 10px;
  font-size: 20px;  /* Media query for smaller screens (e.g., iPhone) */
  @media (max-width: 932px) {
    font-size: 16px; /* adjust font size for smaller screens */
    padding: 8px;
    padding-right: 8px;
  }
}

.copy-link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.cal-link {
  filter: invert(0); /* Invert the colors */
}

.cal-link.active {
  opacity: 0.2;
}

.cal-link:hover {
  cursor: pointer;
  opacity: 0.5;
}

.date-strap {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  align-content: stretch;
  justify-content: space-evenly;
}

.date-landscape {
  position: absolute;
  display: flex;
  top: 20px;
  left: 20px;
  background-color:#fdcf1a ;
  color: black;
  border-radius: 8px;
  z-index: 2001;
  font-size: 16px;
  font-weight: bold;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  align-items: center;
}

.date-landscape span {
  padding: 8px;
}

.date-strap h1 {
  font-weight: bold;
  font-size: 24px;
  text-align: center;  
  @media (max-width: 932px) {
    font-size: 18px;
  }
}

.fade-text {
  font-size: 12pt;
  font-weight: bold;
  color: white;
  background-color: red;
  opacity: 1;
  animation: revealText 1.25s forwards;
  transition: opacity 0.5s ease-in-out;
  margin: 5px;
  padding: 3px;
  position: absolute;
  top: 8px;
  left: 100px;  
  @media (max-width: 932px) {
    font-size: 10pt;
    left: 75px; 
  }
}

.fade-text.hidden {
  opacity: 0;
}