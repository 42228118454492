.dialog-right-overlay {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 300px; /* Adjust width as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 5px;
  z-index: 1001 ;
}

.dialog-left-overlay {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 330px; /* Adjust width as needed */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 5px;
  z-index: 1001 ;
}

.date-right-overlay {
  position: fixed;
  top: 40px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  padding: 1px;
  z-index: 1001 ;
}

.dialog {
  background-color: white;
  padding: 15px;
  padding-top: 10px;
}

.dialog textarea, input {
  width: 100%;
  margin-bottom: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.dialog textarea {
  height: 150px; /* Adjust height as needed */
}

.dialog button {
  display: block;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 5px;
}

.prompt-text {
  font-size: 11px;
}

.about-text {
  font-size: 12px;
}

.datepicker-container {
  position: absolute;
  top: 5pc;
  right: 5px;
  z-index: 1001; /* Ensure it overlays other elements */
}

.debugDisplay {
  background-color: rgb(253, 176, 176);
  padding: 5px;
}