html, body, .base {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;  
}

.base {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;  /* Ensure no scrollbars on the root container */
}

.version {
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  font-size: xx-small;
}

#image-container {
  flex: 1;
  position: relative;
  overflow: hidden;
}

@keyframes revealText {
  0% {
    opacity: 0;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(-25%);
  }
}